import { useLocation, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import topRightArrow from '../../images/top-right-arrow.svg';
import { useInsuranceContext } from './InsuranceContext';
import CPAPCoveragePaymentChoiceModal from './CPAPCoveragePaymentChoiceModal';
import { toggleModal } from '../../features/ui/uiSlice';
import { useUpdateInsuranceInCartMutation } from '../../app/services/cart';

function CPAPGetStartedButton() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [modalOpen, setModalOpen] = useState(false);
    const { hasInsuranceAlternative, insurance } = useInsuranceContext();
    const { recommendedCPAPBundles } = useSelector((state) => state.catalog);
    const { user } = useSelector((state) => state.auth);

    const [updateInsuranceInCart] = useUpdateInsuranceInCartMutation();

    useEffect(() => {
        if (location?.state?.from === '/dashboard/insurance/add') setModalOpen(true);
    }, [location?.state?.from, setModalOpen]);

    async function handleContinueClick() {
        //Insurance will cover CPAP, so take them directly to mask fitting
        if (hasInsuranceAlternative) {
            await updateInsuranceInCart(true);
            dispatch(toggleModal('maskFit'));
            //If they have insurance which covers CPAP or a DME referrer, show them the screen to compare cash pay with insurance
        } else if (
            insurance &&
            (insurance.payer?.CPAPCovered || insurance.payer?.dmeReferralOrganizationId !== null)
        ) {
            navigate('/dashboard/treatment/comparison');
            //If they have a consult key already and no recommendations, show them the mask fit modal so they can get the link again
        } else if (
            user?.patientProfile?.sovaSageConsultKey &&
            recommendedCPAPBundles?.length === 0
        ) {
            dispatch(toggleModal('maskFit'));
            //Otherwise take them to the gem bundle page before they start mask fitting
        } else {
            navigate('/dashboard/treatment/gem-bundle');
        }
    }

    return (
        <>
            <button
                className="btn-primary flex items-center justify-between space-x-4 w-full xl:w-[280px] max-h-[68px]"
                onClick={() => {
                    setModalOpen(true);
                }}>
                <span className="pointer-events-none">
                    {user?.patientProfile?.sovaSageConsultKey ? 'Pending Mask Fit' : 'Get started'}
                </span>
                <img src={topRightArrow} alt="" className="w-2 lg:w-3 pointer-events-none" />
            </button>
            {modalOpen && (
                <CPAPCoveragePaymentChoiceModal
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    handleContinueClick={handleContinueClick}
                />
            )}
        </>
    );
}

export default CPAPGetStartedButton;
