import { motion } from 'framer-motion';
import { ReactComponent as SleepResetLogo } from '../../../images/sleep-reset-logo.svg';
import posthog from 'posthog-js';
import { useSelector } from 'react-redux';

function HighRisk() {
    posthog?.capture('Answered a Question', {
        question: 'Assessment Result',
        answer: 'high_risk',
    });

    const { highRiskSelections, highRiskSelections2, howBroughtToGem } = useSelector(
        (state) => state.assessment.answers,
    );

    const isCoachingPath = howBroughtToGem === 'STRUGGLE_WITH_CPAP';

    return (
        <motion.div
            initial={{ opacity: 0, translateX: '20%' }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: '-20%' }}
            transition={{ ease: 'linear' }}
            className="grid lg:grid-cols-2 lg:justify-between">
            {highRiskSelections?.includes('INSOMNIA') ||
            highRiskSelections2?.includes('INSOMNIA') ? (
                <>
                    <div className="mb-11 ">
                        <h4 className="mb-4 font-heading text-3xl text-purple-fair">
                            ASSESSMENT RESULTS
                        </h4>
                        <h2 className="font-heading text-5xl font-bold lg:mb-5 lg:text-8xl">
                            You may have sleep apnea, but insomnia should be addressed before GEM
                            SLEEP is right for you.
                        </h2>
                    </div>
                    <div>
                        <div className="space-y-8 lg:ml-16 ">
                            <p className="text-base font-medium !leading-relaxed text-gray-light lg:text-2xl">
                                Insomnia can interfere with a home sleep test, such as the one
                                offered through GEM SLEEP, providing inaccurate results.
                            </p>

                            <p className="text-lg/6 font-bold  !leading-relaxed lg:text-2xl">
                                We want to ensure you receive the best possible care for your
                                situation, so we have partnered with Sleep Reset.
                            </p>
                        </div>
                        <div className="my-8 lg:ml-16">
                            <SleepResetLogo />
                        </div>
                        <div>
                            <p className="text-lg/6 font-medium !leading-relaxed text-gray-light lg:ml-16 lg:text-2xl">
                                Sleep Reset is a personalized sleep program that helps members
                                improve their sleep.{' '}
                                <span className=" text-gem-green">
                                    Sleep Reset has offered a special discount to GEM SLEEP members.{' '}
                                </span>
                                <a
                                    className="border-b-2 border-b-green-400 font-bold text-white"
                                    href="https://www.thesleepreset.com/partners/gem-sleep"
                                    target="_blank"
                                    rel="noreferrer">
                                    Learn more about the Sleep Reset program here.
                                </a>
                            </p>
                        </div>
                    </div>
                </>
            ) : isCoachingPath ? (
                <>
                    <div>
                        <h4 className="mb-6 font-heading text-3xl text-purple-fair">
                            ASSESSMENT RESULTS
                        </h4>
                        <h2 className="mb-4 font-heading text-5xl font-bold lg:mb-5 lg:text-8xl">
                            THE GEM SLEEP COACHING PROGRAM MAY NOT BE THE RIGHT FIT.{' '}
                        </h2>
                    </div>
                    <div className="mt-7 space-y-7 text-gray-light lg:ml-16">
                        <p className="text-base font-medium !leading-relaxed lg:text-2xl">
                            Based on the information you provided we recommend visiting an In-Person
                            Sleep Clinic as a next step.{' '}
                            <span className="font-italic">
                                Oftentimes CPAP therapy can be difficult to adjust to if you may
                                have another underlying sleep condition or if there is uncertainty
                                regarding the diagnosis.
                            </span>
                        </p>
                        <p className="text-base font-medium !leading-relaxed lg:text-2xl">
                            You can find a provider in your area at{' '}
                            <a
                                href="https://sleepeducation.org/"
                                target="_blank"
                                rel="noreferrer"
                                className="border-b-2 border-b-green-400 text-white">
                                sleepeducation.org
                            </a>
                            , or in your health insurer's provider directory.
                        </p>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <h4 className="mb-6 font-heading text-3xl text-purple-fair">
                            ASSESSMENT RESULTS
                        </h4>
                        <h2 className="mb-4 font-heading text-5xl font-bold lg:mb-5 lg:text-8xl">
                            You may have Sleep Apnea, but currently GEM SLEEP is not the right
                            solution for you.
                        </h2>
                    </div>
                    <div>
                        <div className="space-y-7 text-gray-light lg:ml-16 ">
                            <p className="text-base font-medium !leading-relaxed lg:text-2xl">
                                We want to ensure that you receive the best possible care for your
                                situation.
                            </p>

                            <p className="text-base font-medium !leading-relaxed lg:text-2xl">
                                Based on your clinical health history, a home sleep test, such as
                                the one offered through GEM SLEEP, is likely not the best fit for
                                you right now.
                            </p>
                        </div>
                        <div className="mt-7 space-y-7 text-gray-light lg:ml-16">
                            <p className="text-base font-medium !leading-relaxed lg:text-2xl">
                                We recommend you meet with a local sleep medicine provider to review
                                your clinical history and determine your next steps.{' '}
                            </p>
                            <p className="text-base font-medium !leading-relaxed lg:text-2xl">
                                You can find a provider in your area at{' '}
                                <a
                                    href="https://sleepeducation.org/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="border-b-2 border-b-green-400 text-white">
                                    sleepeducation.org
                                </a>
                                , or in your health insurer's provider directory.
                            </p>
                        </div>
                    </div>
                </>
            )}
        </motion.div>
    );
}

export default HighRisk;
