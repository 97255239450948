import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { logOut, tokenReceived } from '../../features/auth/authSlice';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;

        if (token) headers.set('Authorization', `Bearer ${token}`);

        return headers;
    },
});

export const api = createApi({
    baseQuery: async (args, api, extraOptions) => {
        await mutex.waitForUnlock();

        if (
            api.endpoint === 'login' ||
            args.url === '/auth/refresh' ||
            args.url === '/auth/verifyCode'
        ) {
            args = { ...args, credentials: 'include' };
        }

        let result = await baseQuery(args, api, extraOptions);

        if (args.url !== '/auth/verifyCode' && result.error && result.error.status === 401) {
            // checking whether the mutex is locked
            if (!mutex.isLocked()) {
                const release = await mutex.acquire();
                try {
                    const refreshResult = await baseQuery(
                        {
                            url: '/auth/refresh',
                            method: 'POST',
                            credentials: 'include',
                        },
                        api,
                        extraOptions,
                    );
                    if (refreshResult.data) {
                        api.dispatch(tokenReceived(refreshResult.data));
                        // retry the initial query
                        result = await baseQuery(args, api, extraOptions);
                    } else {
                        api.dispatch(logOut());
                    }
                } catch (error) {
                    api.dispatch(logOut());
                } finally {
                    // release must be called once the mutex should be released again.
                    release();
                }
            } else {
                // wait until the mutex is available without locking it
                await mutex.waitForUnlock();
                result = await baseQuery(args, api, extraOptions);
            }
        }
        return result;
    },
    tagTypes: [
        'AdminUsers',
        'CareTeamPool',
        'Cart',
        'Categories',
        'Coaches',
        'Feedback',
        'Files',
        'Groups',
        'Insurance',
        'Note',
        'OrderChanges',
        'OrderHistory',
        'OrderInfo',
        'Organization',
        'PatientOrderHistory',
        'Payers',
        'Post',
        'Product',
        'Products',
        'Queries',
        'RecommendedCPAPBundles',
        'ReferralList',
        'ResupplyList',
        'ResupplyStatus',
        'SovaSagePartRecommendations',
        'SquareCatalog',
        'Treatment',
        'User',
        'UserTimeline',
    ],
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: '/auth/login',
                method: 'POST',
                body: {
                    ...{
                        currentTimezone:
                            Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ||
                            'America/New_York',
                    },
                    ...credentials,
                },
            }),
        }),
        verifyCode: builder.mutation({
            query: (data) => ({
                url: '/auth/verifyCode',
                method: 'POST',
                body: {
                    ...{
                        currentTimezone:
                            Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ||
                            'America/New_York',
                    },
                    ...data,
                },
            }),
        }),
        resendCode: builder.mutation({
            query: (credentials) => ({
                url: '/auth/resendCode',
                method: 'POST',
                body: {
                    ...credentials,
                },
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: '/auth/logout',
                method: 'POST',
            }),
        }),
        requestPasswordReset: builder.mutation({
            query: (passwordResetData) => ({
                url: '/auth/request-password-reset',
                method: 'POST',
                body: passwordResetData,
            }),
        }),
        resetPassword: builder.mutation({
            query: (data) => ({
                url: '/auth/reset-password',
                method: 'POST',
                body: data,
            }),
        }),
        getMe: builder.query({
            query: () => ({
                url: '/users/my',
                method: 'GET',
            }),
            providesTags: (result) => (result?.id ? [{ type: 'User', id: result.id }] : []),
        }),
        createUser: builder.mutation({
            query: (userData) => ({
                url: '/users/patients',
                method: 'POST',
                body: {
                    ...{
                        currentTimezone:
                            Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ||
                            'America/New_York',
                    },
                    ...userData,
                },
            }),
        }),
        updateMe: builder.mutation({
            query: (userData) => ({
                url: '/users/my',
                method: 'PUT',
                body: userData,
            }),
            invalidatesTags: (result) => [{ type: 'User', id: result.id }],
        }),
        getUserTimeline: builder.query({
            query: (userId) => ({
                url: `/history/timeline/${userId}`,
                method: 'GET',
            }),
            providesTags: ['UserTimeline'],
        }),
        getPaymentUrl: builder.query({
            query: () => ({
                url: '/collectplus/paymentUrl',
                method: 'GET',
            }),
        }),
        createFeedback: builder.mutation({
            query: (body) => ({
                url: `/feedback`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Feedback'],
        }),
        getFeedback: builder.query({
            query: () => ({
                url: `/feedback`,
                method: 'GET',
            }),
            providesTags: ['Feedback'],
        }),
        assignSleepStudyAsPrescription: builder.mutation({
            query: ({ id }) => ({
                url: `/files/${id}/assignSleepStudyAsPrescription`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Files', id: arg.id }, 'User'],
        }),
        authenticateWithImpersonationToken: builder.mutation({
            query: (impersonation_token) => ({
                url: '/auth/authenticate-impersonation',
                method: 'POST',
                body: { impersonation_token },
            }),
        }),
        getCoaches: builder.query({
            query: () => ({
                url: `/users/coaches`,
                method: 'GET',
            }),
            providesTags: ['Coaches'],
        }),
    }),
});

export const {
    //Auth
    useAuthenticateWithImpersonationTokenMutation,
    useCreateUserMutation,
    useLoginMutation,
    useLogoutMutation,
    useRequestPasswordResetMutation,
    useResendCodeMutation,
    useResetPasswordMutation,
    useVerifyCodeMutation,
    //Patient Data
    useGetCoachesQuery,
    useGetMeQuery,
    useGetUserTimelineQuery,
    useUpdateMeMutation,
    useGetPaymentUrlQuery,
    useCreateFeedbackMutation,
    useGetFeedbackQuery,
    useAssignSleepStudyAsPrescriptionMutation,
} = api;
