import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

//Components
import CoachingResult from './CoachingResult';
import HasSleepApnea from './HasSleepApnea';
import HighRisk from './HighRisk';
import NoSleepApnea from './NoApnea';
import LowScore from './LowScore';

import ReactGA from 'react-ga4';

function AssessmentResult() {
    const posthog = usePostHog();
    const { answers, apneaScore, hasHighRisk } = useSelector((state) => state.assessment);

    const isCoachingPath = answers.howBroughtToGem === 'STRUGGLE_WITH_CPAP';
    //Has additional considerations to supplement low score
    const lowScoreAdditions =
        answers.lowScoreAdditions && !answers.lowScoreAdditions?.includes('NONE');
    //Allow through if their Breath IQ app has indicated sleep apnea, or they have a score of at least 1 and any additional considerations
    const allowThroughWithLowScore =
        (apneaScore > 0 && lowScoreAdditions) || answers.sleepNumberBreathIqIrregularities;

    //useEffect to handle analytic captures based on assessment result
    useEffect(() => {
        if (
            Array.isArray(answers.highRiskSelections) &&
            answers.highRiskSelections.length > 0 &&
            answers.highRiskSelections.indexOf('NONE') < 0 &&
            apneaScore >= 3
        ) {
            posthog?.capture('Received Assessment Result', {
                result: 'Apnea - high risk',
                apneaScore,
            });

            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'Apnea - high risk',
            });
        } else if (apneaScore >= 3) {
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'Apnea',
            });

            posthog?.capture('Received Assessment Result', { result: 'Apnea', apneaScore });
        } else if (allowThroughWithLowScore) {
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'Low Score',
            });

            posthog?.capture('Received Assessment Result', { result: 'Low Score', apneaScore });
        } else {
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'No Apnea',
            });

            posthog?.capture('Received Assessment Result', { result: 'No Apnea', apneaScore });
        }
        // eslint-disable-next-line
    }, []);

    // Show one of four outcomes from assessment
    if (hasHighRisk) {
        return <HighRisk />;
    } else if (isCoachingPath) {
        return <CoachingResult />;
    } else if (apneaScore >= 3) {
        return <HasSleepApnea />;
    } else if (allowThroughWithLowScore) {
        return <LowScore />;
    } else {
        return <NoSleepApnea />;
    }
}

export default AssessmentResult;
