import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateConsentMutation, useGetPatientConsentsQuery } from '../../app/services/insurance';
import { useGetWebflowTermsQuery } from '../../app/services/webflow';
import { CustomCheckbox } from '../../components/InputComponents';
import { toggleModal, toggle } from '../../features/ui/uiSlice';
import { overlayVariants } from '../../utils/constants';
import { setConsultRequired } from '../../features/cart/cartSlice';
import { useCheckoutContext } from '../Checkout/CheckoutContext';
import { useInsuranceContext } from '../Insurance/InsuranceContext';
import { useUpdateInsuranceInCartMutation } from '../../app/services/cart';

function PayerText() {
    const { cpapInCart } = useSelector((state) => state.cart);

    //Context
    const { insurance } = useInsuranceContext();

    const informationList = cpapInCart ? (
        <ul className="list-disc list-outside space-y-2">
            <li>We have received a referral from your Care Navigator for GEM Services</li>
            <li>
                Once you place your order, your insurance will let us know if there is any patient
                responsibility for the CPAP Starter Bundle. If yes, we will send you an invoice for
                the amount due.*
            </li>
            <li>Once we receive payment, we will ship out your CPAP.</li>
            <li>
                Once you receive your CPAP, you will begin working with your assigned GEM Coach.
            </li>
            <p className="text-xs">*(Payment Plans are available)</p>
        </ul>
    ) : (
        <ul className="list-disc list-outside space-y-2">
            <li>We have received a referral from your Care Navigator for GEM Services</li>
            <li>
                Your order will be reviewed by a clinician, if approved your order will be shipped.
            </li>
            <li>
                Based on your insurance plan, there may be a patient portion responsibility. If yes,
                we will send you an invoice for the amount due once you complete your Home Sleep
                Test.*
            </li>
            <p className="text-xs">*(Payment Plans are available)</p>
        </ul>
    );

    if (
        ['Trinity Care Navigation', 'The Care Navigation Benefit through Prime Dx'].includes(
            insurance?.payer?.name,
        )
    ) {
        return (
            <div className="p-8 lg:p-14">
                <h5 className="text-center mb-5 font-heading text-4xl font-bold lg:text-5xl">
                    Good News! You have coverage with the {insurance.payer?.name}.
                </h5>
                <p className="underline pb-2">How does this work:</p>
                {informationList}
            </div>
        );
    } else {
        return (
            <h5 className="text-center mb-5 font-heading text-4xl font-bold lg:text-5xl p-8 lg:p-14">
                Good News! Your services are fully covered by {insurance.payer?.name}.
            </h5>
        );
    }
}
const CoveredBenefit = () => {
    const dispatch = useDispatch();

    //Redux state Values
    const { agreedToInsuranceBenefits } = useSelector((state) => state.ui);
    const { hstInCart, isConsultOrder, lowScoreConsultRequired, stateRequiredTelehealth } =
        useSelector((state) => state.cart);

    //Context
    const { setCheckoutStep } = useCheckoutContext();
    const { insurance } = useInsuranceContext();

    //Queries
    const { data: consents } = useGetPatientConsentsQuery();
    const { data: webflowTerms } = useGetWebflowTermsQuery();
    const [createConsent] = useCreateConsentMutation();
    const [updateInsuranceInCart] = useUpdateInsuranceInCartMutation();

    const previouslyAgreedToBenefits = consents?.results.find(
        (c) => c.documentType === 'assignment-of-benefit-responsibility',
    );

    const onSubmit = async () => {
        const benefitsConsentData = webflowTerms?.items?.find(
            (t) => t.slug === 'assignment-of-benefit-responsibility',
        );

        if (benefitsConsentData && !previouslyAgreedToBenefits) {
            await createConsent([
                {
                    id: benefitsConsentData._id,
                    version: '' + benefitsConsentData?.version,
                    name: benefitsConsentData.name,
                    documentType: benefitsConsentData.slug,
                },
            ]);
        }

        dispatch(
            setConsultRequired(stateRequiredTelehealth && hstInCart) || lowScoreConsultRequired,
        );
        //Marks cart as using insurance
        await updateInsuranceInCart(true);

        if (stateRequiredTelehealth && hstInCart) {
            setCheckoutStep('required-consult-booking');
        } else if (isConsultOrder) {
            setCheckoutStep('consult-booking');
        } else {
            setCheckoutStep('address-page');
        }
    };

    return (
        consents && (
            <motion.div initial="hidden" animate="visible" exit="hidden" variants={overlayVariants}>
                <PayerText payer={insurance?.payer} />

                <Formik
                    initialValues={{
                        agreeToBenefits: agreedToInsuranceBenefits,
                    }}
                    enableReinitialize
                    onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form className="space-y-6 px-8 lg:px-14">
                            <CustomCheckbox
                                theme="light"
                                name="agreeToBenefits"
                                id="agreeToBenefits"
                                className={`${previouslyAgreedToBenefits ? 'hidden' : ''}`}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    if (!agreedToInsuranceBenefits) {
                                        dispatch(toggleModal('assignmentOfBenefits'));
                                    } else {
                                        dispatch(toggle({ key: 'agreedToInsuranceBenefits' }));
                                    }
                                }}
                                label={
                                    <>
                                        I agree to the{' '}
                                        <span className="link break-words hover:cursor-pointer">
                                            Assignment of Benefits and Patient Responsibility
                                        </span>
                                    </>
                                }
                            />

                            <button
                                className="btn-primary w-full"
                                type="submit"
                                disabled={!agreedToInsuranceBenefits || isSubmitting}>
                                Continue
                            </button>
                        </Form>
                    )}
                </Formik>
            </motion.div>
        )
    );
};

export default CoveredBenefit;
