import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateMeMutation } from '../../app/services/auth';
import {
    useCheckEligibilityMutation,
    useCreatePatientInsuranceMutation,
    useGetPatientConsentsQuery,
    useGetPayersQuery,
    useLazyGetMyInsuranceQuery,
    useUpdatePatientInsuranceMutation,
} from '../../app/services/insurance';
import { useUpdateInsuranceInCartMutation } from '../../app/services/cart';

export const InsuranceDataContext = createContext(null);

export function InsuranceContextProvider({ children }) {
    const dispatch = useDispatch();

    //Local state
    const [chosenPayer, setChosenPayer] = useState(null);
    const [ready, setReady] = useState(false);

    //redux state selectors
    const { user } = useSelector((state) => state.auth);

    //query hooks
    const [getInsurance, { data: insurance, isFetching: insuranceFetching }] =
        useLazyGetMyInsuranceQuery(user?.id, {
            skip: !user,
        });
    const [createPatientInsurance] = useCreatePatientInsuranceMutation();
    const [updatePatientInsurance] = useUpdatePatientInsuranceMutation();
    const [updateInsuranceInCart] = useUpdateInsuranceInCartMutation();
    const [checkEligibility] = useCheckEligibilityMutation();
    const [updateMe] = useUpdateMeMutation();
    const { data: payers } = useGetPayersQuery();
    const { data: consents } = useGetPatientConsentsQuery({ skip: !user });

    useEffect(() => {
        if (user) {
            getInsurance(user.id);
        }
    }, [getInsurance, user]);

    //API calls
    async function checkIfElibigilityCheckRequired(ins) {
        if (ins.insuranceStatus === 'Active') {
            await updateInsuranceInCart(true);
            return false;
        } else if (ins.payer.payerType === 'OTHER') {
            await updateInsuranceInCart(false);
            return false;
        } else if (!ins.payer.supportsEligibilityCheck) {
            await updateInsuranceInCart(true);
            return false;
        } else {
            return true;
        }
    }

    const addPatientInsurance = async (insuranceData) => {
        const { data: insuranceAddResult, error } = await createPatientInsurance({
            userId: user.id,
            data: {
                payerId: insuranceData.payer.id,
                memberId: insuranceData.memberId,
                groupId: insuranceData.groupId,
                otherPayerName: insuranceData.otherPayerName,
                multiplan: false,
            },
        });

        if (error) {
            //return error to form
            return { status: 'error', data: error.data };
        }

        if (checkIfElibigilityCheckRequired(insuranceAddResult)) {
            //dummy order, should be replaced with Niko products from the cart eventually
            const { error: orderError } = checkEligibility(user.id);

            if (orderError) {
                return;
            }
        }

        const newInsurance = await getInsurance(user.id);
        return newInsurance;
    };

    const updatePatientCurrentInsurance = async (userId, insuranceId, changedValues) => {
        // if patientFirstName or patientLastName changed or patientDob, update user
        if (
            changedValues.patientFirstName ||
            changedValues.patientLastName ||
            changedValues.patientDob
        ) {
            const patientPayload = {
                firstName: changedValues.patientFirstName,
                lastName: changedValues.patientLastName,
                birthdate: changedValues.patientDob,
            };
            const updatePatientResult = await updateMe(patientPayload);
            if (updatePatientResult.error) {
                return { status: 'error', data: updatePatientResult.error.data };
            }
        }

        const insurancePayload = {
            userId,
            insuranceId,
            data: { ...changedValues, insuranceStatus: 'Review' },
        };

        if (changedValues.payer) {
            insurancePayload.data.payerId = changedValues.payer.id;
            delete insurancePayload.data.payer;
        }

        const { data: updatedInsurance, error } = await updatePatientInsurance(insurancePayload);
        if (error) {
            return { status: 'error', data: error.data };
        }

        if (checkIfElibigilityCheckRequired(updatedInsurance)) {
            //dummy order, should be replaced with Niko products from the cart eventually
            const { error: orderError } = checkEligibility(user.id);

            if (orderError) {
                return;
            }
        }

        const newInsurance = await getInsurance(user.id);
        return newInsurance;
    };

    function checkIfInsuranceAlternative(insurance) {
        return (
            insurance?.insuranceType === 'ALTERNATIVE' &&
            insurance?.insuranceStatus.toLowerCase() === 'active'
        );
    }

    const hasInsuranceAlternative = checkIfInsuranceAlternative(insurance);

    useEffect(() => {
        if (user && !insuranceFetching && payers) setReady(true);
    }, [insuranceFetching, user, payers]);

    const contextValue = {
        chosenPayer,
        consents,
        hasInsuranceAlternative,
        insurance,
        insuranceFetching,
        payers,
        ready,
        addPatientInsurance,
        checkIfElibigilityCheckRequired,
        getInsurance,
        setChosenPayer,
        updatePatientCurrentInsurance,
    };

    return (
        <InsuranceDataContext.Provider value={contextValue}>
            {children}
        </InsuranceDataContext.Provider>
    );
}

export function useInsuranceContext() {
    const context = useContext(InsuranceDataContext);
    if (!context) {
        throw new Error('Insurance Context must be used within InsuranceContainerNew');
    }
    return context;
}
