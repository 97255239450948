import React from 'react';
import { useSelector } from 'react-redux';

const ImpersonationBanner = () => {
    const { impersonatorId, user } = useSelector((state) => state.auth);

    if (!impersonatorId) {
        return null;
    }

    return (
        <div className="bg-gem-green text-black py-2 px-4 text-center font-medium fixed top-0 left-0 w-full z-50 shadow-md">
            <div className="container mx-auto flex items-center justify-center">
                <span className="text-white font-bold">
                    IMPERSONATION MODE ACTIVE - You are viewing as {user?.email || 'another user'}
                </span>
            </div>
        </div>
    );
};

export default ImpersonationBanner;
