import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../app/services/auth';

const initialState = {
    appointment: null,
    consultRequired: false,
    cpapInCart: false,
    discount: 0,
    discountCode: '',
    hstInCart: false,
    isConsultOrder: false,
    lowScoreConsultRequired: false,
    orderId: null,
    orderItems: [],
    stateRequiredTelehealth: false,
    subtotal: 0,
    suppliesInCart: false,
    usingInsurance: undefined,
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState: initialState,
    reducers: {
        setConsultRequired: (state, { payload }) => {
            state.consultRequired = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.getCart.matchFulfilled, (state, { payload }) => {
                if (!payload) {
                    return;
                }
                if (payload.orderItems.length === 0) {
                    state.orderItems = [];
                    state.cpapInCart = false;
                    state.hstInCart = false;
                    state.isConsultOrder = false;
                    state.suppliesInCart = false;
                } else {
                    // determine what types of items are in cart
                    state.suppliesInCart = payload.orderItems.some(
                        (p) => p.itemCategory === 'SUPPLIES',
                    );
                    state.hstInCart = payload.orderItems.some(
                        (p) => p.itemCategory === 'SLEEP_STUDY',
                    );
                    state.cpapInCart = payload.orderItems.some(
                        (p) => p.itemCategory === 'CPAP_SUPPLIES',
                    );
                    //Consult orders are those with a CLINICAL CONSULT that is not a required consult
                    state.isConsultOrder =
                        payload.orderItems.some((p) => p.itemCategory === 'CLINICAL_CONSULTS') &&
                        !payload.orderItems.some((p) => p.sku === 'REQCONSULT');
                    state.orderItems = payload.orderItems;
                }
                // we aren't charging tax directly
                // we will need to subtract it from the subtotal
                // when we break it out.
                state.orderId = payload.id;
                state.discountCode = payload.discountCode;
                state.subtotal = payload.total;
                state.discount = payload.totalDiscount;
                state.appointment = payload.appointments.find(
                    (appointment) => appointment.appointmentStatus === 'SCHEDULED',
                );
                state.usingInsurance = payload.orderType === 'INSURANCE';
            })
            .addMatcher(api.endpoints.getStateServices.matchFulfilled, (state, { payload }) => {
                state.stateRequiredTelehealth = payload?.telehealthForState;
            })
            .addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
                return initialState;
            })
            .addMatcher(api.endpoints.getStateServices.matchFulfilled, (state, { payload }) => {
                state.stateRequiredTelehealth = payload?.telehealthForState;
            })
            .addMatcher(api.endpoints.getMe.matchFulfilled, (state, { payload }) => {
                //Require consult for users who have low score additions other than "NONE" when ordering HST
                state.lowScoreConsultRequired =
                    payload.patientProfile?.stopBangResult?.lowScoreAdditions?.length > 0 &&
                    !payload.patientProfile?.stopBangResult?.lowScoreAdditions?.includes['NONE'] &&
                    payload.patientProfile?.mdStatus === 'NOT_INITIATED';
            })
            .addMatcher(
                api.endpoints.updateInsuranceInCart.matchFulfilled,
                (state, { payload }) => {
                    if (!payload) {
                        return;
                    }
                    if (payload.orderItems.length === 0) {
                        state.orderItems = [];
                        state.cpapInCart = false;
                        state.hstInCart = false;
                        state.isConsultOrder = false;
                        state.suppliesInCart = false;
                    } else {
                        // determine what types of items are in cart
                        state.suppliesInCart = payload.orderItems.some(
                            (p) => p.itemCategory === 'SUPPLIES',
                        );
                        state.hstInCart = payload.orderItems.some(
                            (p) => p.itemCategory === 'SLEEP_STUDY',
                        );
                        state.cpapInCart = payload.orderItems.some(
                            (p) => p.itemCategory === 'CPAP_SUPPLIES',
                        );
                        //Consult orders are those with a CLINICAL CONSULT that is not a required consult
                        state.isConsultOrder =
                            payload.orderItems.some(
                                (p) => p.itemCategory === 'CLINICAL_CONSULTS',
                            ) && !payload.orderItems.some((p) => p.sku === 'REQCONSULT');
                        state.orderItems = payload.orderItems;
                    }
                    // we aren't charging tax directly
                    // we will need to subtract it from the subtotal
                    // when we break it out.
                    state.orderId = payload.id;
                    state.discountCode = payload.discountCode;
                    state.subtotal = payload.total;
                    state.discount = payload.totalDiscount;
                    state.appointment = payload.appointments.find(
                        (appointment) => appointment.appointmentStatus === 'SCHEDULED',
                    );
                    state.usingInsurance = payload.orderType === 'INSURANCE';
                },
            );
    },
});

export const { setConsultRequired } = cartSlice.actions;

export default cartSlice.reducer;
