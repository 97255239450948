import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useGetAdviceQuotesQuery, useGetBlogPostsQuery } from '../../app/services/webflow';
import { ArticlesSlider, HomeTimeline, QuoteCard } from '../../components';
import { parentPageVariants } from '../../utils/constants';
import { useGetHomeStatus } from '../../utils/useGetHomeStatus/useGetHomeStatus';
import { FeedbackBox } from './FeedbackBox';
import { VariableUpNext } from './VariableUpNext';
import { PageHeader } from '../../components/LayoutComponents';
import { useGetFeedbackQuery } from '../../app/services/auth';
import { toggle } from '../../features/ui/uiSlice';

function Home() {
    const dispatch = useDispatch();
    const variableContent = useGetHomeStatus();

    //Redux state values
    const { user } = useSelector((state) => state.auth);
    const { showFeedbackBox } = useSelector((state) => state.ui);

    //Queries and mutations
    const { data: adviceQuotes } = useGetAdviceQuotesQuery();
    const { data: blogPosts } = useGetBlogPostsQuery();
    const { data: feedback } = useGetFeedbackQuery();

    const filteredPosts = blogPosts?.filter((item) =>
        variableContent?.blogPostIds?.includes(item.slug),
    );

    const quoteLinkBlogPost = blogPosts?.find((item) => item.slug === variableContent.quoteLink);
    const adviceQuote = adviceQuotes?.items?.find((item) => item.slug === variableContent.quoteId);

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={parentPageVariants}
            transition={{ ease: [0.5, 1, 0.89, 1], duration: 1 }}>
            <div className="layout-outlet-root">
                <header className="mb-6 lg:mb-14">
                    <PageHeader>
                        <span className="text-gray-six">Looking fresh</span>
                        {user?.firstName && (
                            <span className="ph-no-capture">
                                <i className="normal-case text-gray-six">,</i> {user.firstName}
                            </span>
                        )}
                    </PageHeader>
                </header>
                <div className="space-y-4 lg:grid lg:grid-cols-3 lg:gap-10 lg:space-y-0">
                    <div className="lg:pb-20">
                        <p className="mb-4 font-semibold">Up next</p>

                        <VariableUpNext />

                        <HomeTimeline />

                        <button
                            className="btn-shell w-full text-sm mt-4 lg:mt-0"
                            onClick={() => {
                                dispatch(toggle({ key: 'orderHistoryOpen' }));
                            }}>
                            View my gem history
                        </button>
                    </div>

                    <div className="col-span-2 space-y-8">
                        {blogPosts && (
                            <ArticlesSlider
                                className="mb-10"
                                label="Sleep Resources"
                                slidesPerView={1.1}
                                slidesPerViewDesktop={2}
                                buttonType="viewAll"
                                viewAllLink="resources"
                                slides={filteredPosts}
                            />
                        )}
                        <div>
                            <p className="mb-2 font-semibold">GEM Advice</p>
                            {adviceQuotes && (
                                <QuoteCard
                                    className="mb-12"
                                    quoteData={adviceQuote}
                                    link={variableContent.quoteLink}
                                    linkText={
                                        variableContent.quoteLinkText
                                            ? variableContent.quoteLinkText
                                            : quoteLinkBlogPost
                                              ? quoteLinkBlogPost.name
                                              : 'Learn More'
                                    }
                                    isInternal={variableContent.quoteLinkIsInternal}
                                    actionStateObject={variableContent.actionStateObject}
                                />
                            )}

                            {feedback?.length === 0 && showFeedbackBox && <FeedbackBox />}
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default Home;
