import { useSelector } from 'react-redux';
import { PageHeader } from '../../components/LayoutComponents';
import Howell from '../../images/headshots/dr_Howell.jpg';
import Khranke from '../../images/headshots/dr_Krahnke.jpg';
import Vining from '../../images/headshots/dr_vining.jpg';
import Mindy from '../../images/headshots/Mindy-Headshot.jpg';
import Anika from '../../images/headshots/Anika-Headshot.png';
import { AppointmentSchedulingButton } from '../../components/Appointments';
import { useGetCoachesQuery } from '../../app/services/auth';

function StaffMemberDisplay({ title, imageUrl }) {
    return (
        <li className="w-full max-w-full md:max-w-[180px]">
            <div>
                <div>
                    <div className="relative w-full pt-[100%]">
                        <div
                            className="absolute bg-no-repeat bg-cover bg-[center_bottom] inset-0 bg-white"
                            style={{ backgroundImage: `url(${imageUrl})` }}></div>
                    </div>
                </div>
                <p className="text-lg">{title}</p>
            </div>
        </li>
    );
}

function ClinicalTeamMemberDisplay({ name, title, imageUrl }) {
    return (
        <li className="w-full max-w-full md:max-w-[180px]">
            <div>
                <div>
                    <div className="relative w-full pt-[100%]">
                        <div
                            className="absolute bg-no-repeat bg-cover bg-[center_bottom] inset-0 bg-white"
                            style={{ backgroundImage: `url(${imageUrl})` }}></div>
                    </div>
                </div>
                <p className="font-heading text-2xl">{name}</p>
                <p className="text-lg">{title}</p>
            </div>
        </li>
    );
}

function CareTeamMember({ coach }) {
    return (
        <li className="w-full max-w-full md:max-w-[180px]">
            <div>
                <div>
                    <div className="relative w-full pt-[100%]">
                        <div
                            className="absolute bg-no-repeat bg-cover bg-[center_bottom] inset-0 bg-white"
                            style={{
                                backgroundImage: `${`url(${coach.imageUrl})`}`,
                            }}></div>
                    </div>
                </div>
                <p className="text-lg">
                    Coach
                    {coach.assignedToPatient ? (
                        <span>
                            {' - '}
                            {coach.firstName}{' '}
                            <span className="block sm:text-sm">(Your Assigned Coach)</span>
                        </span>
                    ) : null}
                </p>
            </div>
        </li>
    );
}

function CareTeam() {
    const { user } = useSelector((state) => state.auth);
    const { dmeReferral } = useSelector((state) => state.ui);

    const { data: coaches } = useGetCoachesQuery(undefined);

    console.log(coaches);

    const complianceVisitToSchedule =
        user?.patientProfile?.treatments?.find(
            (treatment) => treatment.isActive && treatment.treatmentType === 'CPAP',
        ) || dmeReferral?.referralStatus === 'REFERRED';

    const diagnosisToReview =
        (user?.patientProfile?.mdStatus === 'HST_COMPLETE' ||
            user?.patientProfile?.mdStatus === 'UPLOADED_SLEEP_STUDY_APPROVED') &&
        !complianceVisitToSchedule;

    return (
        <>
            <div className="layout-outlet-root">
                <header className="mb-4 max-w-4xl">
                    <PageHeader>
                        <>
                            <span className="ph-no-capture text-gray-darker">
                                {user ? `${user.firstName}'s` : `...`}
                            </span>{' '}
                            <span className="text-gem-purple">Care team</span>
                        </>
                    </PageHeader>
                </header>
                <section className="mt-2 max-w-4xl">
                    <h3 className="mb-2 font-heading text-4xl">Your Patient Experience Team</h3>
                    <p>
                        This group of individuals will help you navigate the GEM experience from
                        ordering an HST through adjusting to therapy. Once you order a CPAP your
                        assigned coach will reach out to you.
                    </p>
                    <ul className="flex flex-wrap gap-8 mt-5">
                        {coaches?.map((coach) =>
                            coach.imageUrl ? <CareTeamMember coach={coach} /> : null,
                        )}

                        <StaffMemberDisplay title="Order Specialist" imageUrl={Mindy} />
                        <StaffMemberDisplay title="Patient Care Specialist" imageUrl={Anika} />
                    </ul>
                </section>
                <section className="mx-0 my-16 max-w-4xl">
                    <header className="max-w-4xl mb-12">
                        <h3 className="mb-2 font-heading text-4xl">Your Clinical Team</h3>
                        <p>
                            Your GEM clinicians specialize in sleep apnea. They follow you through
                            your journey, including reviewing sleep study results, creating
                            personalized treatment recommendations, writing prescriptions and
                            overseeing your treatment. If you'd like to meet them virtually please
                            schedule a consult.
                        </p>
                        <div className="w-full">
                            {(diagnosisToReview || complianceVisitToSchedule) && (
                                <AppointmentSchedulingButton
                                    label={
                                        complianceVisitToSchedule
                                            ? 'Schedule a compliance visit'
                                            : 'Schedule a consult'
                                    }
                                    sku={complianceVisitToSchedule ? 'COMPLIANCE' : 'DIACLINICAL'}
                                    styling="btn-primary inline-flex items-center mt-4 w-full"
                                />
                            )}
                        </div>
                    </header>
                    <ul className="flex flex-wrap gap-8 mt-5">
                        <ClinicalTeamMemberDisplay
                            name="Dr. Jason Krahnke"
                            title="DO"
                            imageUrl={Khranke}
                        />
                        <ClinicalTeamMemberDisplay
                            name="Dr. Mike Howell"
                            title="MD"
                            imageUrl={Howell}
                        />
                        <ClinicalTeamMemberDisplay
                            name="Julie Vining"
                            title="NP"
                            imageUrl={Vining}
                        />
                    </ul>
                </section>
            </div>
        </>
    );
}

export default CareTeam;
