import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useCreateUserMutation } from '../../../app/services/auth';
import { useGetWebflowTermsQuery } from '../../../app/services/webflow';
import CreateAccountForm from './CreateAccountForm';
import { usePostHog } from 'posthog-js/react';

function SaveApneaAssessment() {
    const posthog = usePostHog();
    const { answers, homeSleepStudyStatus, partnerId } = useSelector((state) => state.assessment);
    const { data: webflowTerms } = useGetWebflowTermsQuery();
    const [createUser, { error, isSuccess, isLoading }] = useCreateUserMutation();

    const submitHandler = async (formData) => {
        const informedConsentData = webflowTerms?.items?.find((t) => t.slug === 'consent');
        const consentsPayload = informedConsentData
            ? [
                  {
                      id: informedConsentData._id,
                      version: '' + informedConsentData?.version,
                      name: informedConsentData.name,
                      documentType: informedConsentData.slug,
                  },
              ]
            : [];
        const formDataClone = { ...formData };
        const answersClone = {
            ...answers,
            //This question comes in two parts, but we mark true if they answer either as YES
            doesStopBreathing: answers.doesStopBreathing || answers.doesStopBreathing2,
        };

        formDataClone.phone = formDataClone.phone.replace(/[- )(]/g, '');
        formDataClone.birthdate = answersClone.dateOfBirth;
        formDataClone.stopBangResult = answersClone;
        formDataClone.consents = consentsPayload;
        formDataClone.referFromOrganizationId = partnerId || undefined;
        if (homeSleepStudyStatus) formDataClone.homeSleepStudyStatus = homeSleepStudyStatus;

        formDataClone.redirectUrl = window.location.origin + '?fromEmailVerification=yes';

        delete formDataClone.confirmPassword;
        delete formDataClone.confirmEmail;
        delete formDataClone.stopBangResult.highRiskSelections;
        delete formDataClone.stopBangResult.doesStopBreathing2;
        if (formDataClone.referralSource === '') delete formDataClone.referralSource;

        //Add posthog session ID to submission
        const postHogSessionID = posthog?.get_session_id();
        if (postHogSessionID) {
            formDataClone.postHogSessionID = postHogSessionID;
        }

        await createUser(formDataClone);

        posthog?.capture('Submitted Create Account Form');
    };

    const hasValidPriorStudy =
        answers.previouslyDiagnosedWithSleepApnea &&
        answers.hasPriorStudy &&
        answers.howBroughtToGem !== 'NEED_NEW_SLEEP_TEST' &&
        !answers.gained20Lbs;

    const isCoachingPath = answers.howBroughtToGem === 'STRUGGLE_WITH_CPAP';

    return isSuccess ? (
        <Navigate to="/account-created" state={{ fromSaveAssessment: true }} />
    ) : (
        <>
            <motion.div
                initial={{ opacity: 0, translateX: '20%' }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: '-20%' }}
                transition={{ ease: [0.5, 1, 0.89, 1] }}
                className="lg:flex">
                <div className="lg:w-2/5">
                    <h2 className="mb-4 font-heading text-5xl lg:mb-5 lg:mt-14 lg:text-[80px]">
                        {hasValidPriorStudy || isCoachingPath
                            ? 'SET UP YOUR GEM SLEEP PROFILE.'
                            : "LET'S GET YOU THE HOME SLEEP STUDY."}
                    </h2>
                    <p className="mb-14 text-lg font-bold lg:mb-0  lg:text-2xl">
                        {hasValidPriorStudy && !isCoachingPath
                            ? 'After account creation you will be asked to upload a copy of your current sleep study.'
                            : 'The first step is to create a GEM account. '}
                    </p>
                    {error && (
                        <p className="mt-3 font-bold text-red">
                            Unable to create an account? CHECK YOUR EMAIL FOR NEXT STEPS.
                        </p>
                    )}
                </div>

                <div className="border-8 border-purple-medium border-opacity-20 p-8 lg:ml-16 lg:w-3/5 lg:p-14">
                    <CreateAccountForm submitHandler={submitHandler} formSubmitting={isLoading} />
                </div>
            </motion.div>
        </>
    );
}

export default SaveApneaAssessment;
