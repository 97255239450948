import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggle, toggleModal } from '../../features/ui/uiSlice';
import { motion } from 'framer-motion';
import { routes } from '../../utils/routes';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as Tagline } from '../../images/gem-sleep-tagline.svg';
import { ReactComponent as Diamond } from '../../images/diamond.svg';
import AppointmentReminder from '../Appointments/AppointmentReminder';

const Nav = () => {
    const dispatch = useDispatch();
    const { impersonatorId } = useSelector((state) => state.auth);
    const { navOpen } = useSelector((state) => state.ui);

    return (
        <motion.nav
            className={`fixed ${impersonatorId ? 'top-6' : 'top-0'} left-0 z-40 min-h-screen w-screen transition duration-200 ease-out-quad lg:static ${
                navOpen ? 'translate-x-0' : '-translate-x-full'
            } lg:w-1/5 lg:translate-x-0`}>
            <div
                className="fixed top-0 left-0 h-screen w-screen backdrop-blur-md lg:hidden"
                onClick={() => {
                    dispatch(toggle({ key: 'navOpen' }));
                }}></div>
            <div className="relative h-screen w-10/12 bg-purple-dark px-8 py-14 text-white lg:sticky lg:top-0 lg:w-full lg:px-6">
                <div className="mb-10 flex items-center">
                    <Logo className="max-w-[40px]" />
                    <Tagline className="ml-2 max-w-[168px]" />
                </div>

                <ul className="space-y-4 lg:pl-12">
                    {routes.map((r, idx) => (
                        <NavLink
                            onClick={() => {
                                dispatch(toggle({ key: 'accountOpen', override: false }));
                                dispatch(toggle({ key: 'cartOpen', override: false }));
                                dispatch(toggle({ key: 'editScreenOpen', override: false }));
                                dispatch(toggle({ key: 'orderHistoryOpen', override: false }));
                            }}
                            className={({ isActive }) =>
                                `main-nav-link group ${
                                    isActive
                                        ? 'nav-link-active translate-x-2 opacity-100'
                                        : 'opacity-50'
                                }`
                            }
                            to={r.path}
                            key={idx}
                            end={r.path === '/dashboard'}>
                            <Diamond className="diamond absolute -left-5 top-1/2 -translate-x-5 -translate-y-1/2 opacity-0 transition duration-500 pointer-events-none" />
                            {r.breadcrumb}
                        </NavLink>
                    ))}
                </ul>

                <AppointmentReminder type="nav" />

                <div className="absolute bottom-0 left-0 w-full px-8 lg:px-5 xl:px-9">
                    <div className="flex items-center">
                        <div className="relative h-6 w-6 rounded-md bg-white/10">
                            <Logo className="absolute-center max-w-[15px]" />
                        </div>

                        <span className="ml-3 text-xs">
                            V{process.env.REACT_APP_CURRENT_VERSION}
                        </span>
                    </div>
                    <ul className="flex items-center space-x-6 text-xs">
                        <li>
                            <button
                                className="py-4 text-white/50 hover:text-white"
                                onClick={() => {
                                    dispatch(toggleModal('contact'));
                                }}>
                                Contact Us
                            </button>
                        </li>
                        <li>
                            <button
                                className="py-4 text-white/50 hover:text-white"
                                onClick={() => {
                                    dispatch(toggleModal('privacy'));
                                }}>
                                Privacy
                            </button>
                        </li>
                        <li>
                            <button
                                className="py-4 text-white/50 hover:text-white"
                                onClick={() => {
                                    dispatch(toggleModal('terms'));
                                }}>
                                Terms
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </motion.nav>
    );
};

export default Nav;
