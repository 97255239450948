import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthenticateWithImpersonationTokenMutation } from '../../app/services/auth';
import { useSelector } from 'react-redux';
import posthog from 'posthog-js';

const Impersonate = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get('token');
    const [authenticateWithToken, { isLoading, isError, error }] =
        useAuthenticateWithImpersonationTokenMutation();
    const { impersonatorId } = useSelector((state) => state.auth);

    useEffect(() => {
        const authenticate = async () => {
            if (token) {
                try {
                    const response = await authenticateWithToken(token);
                    // Redirect to dashboard after successful authentication and log impersonation to posthog
                    posthog?.identify(response.data.impersonatorId);
                    posthog?.capture('Impersonating user ' + response.data.user.id);
                    navigate('/dashboard');
                } catch (err) {
                    console.error('Impersonation failed:', err);
                }
            } else {
                // No token provided, redirect to home
                navigate('/');
            }
        };

        authenticate();
    }, [token, authenticateWithToken, navigate]);

    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen">
                <div className="text-xl font-semibold mb-4">Authenticating...</div>
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (isError) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen">
                <div className="text-xl font-semibold mb-4 text-red-500">Authentication Failed</div>
                <div className="text-gray-600 mb-4">
                    {error?.data?.message || 'Invalid or expired impersonation token.'}
                </div>
                <button
                    onClick={() => navigate('/')}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                    Return to Home
                </button>
            </div>
        );
    }

    // This should not be visible as we redirect after successful authentication
    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <div className="text-xl font-semibold mb-4">
                {impersonatorId ? 'Impersonation successful!' : 'Processing...'}
            </div>
            <div className="text-gray-600">Redirecting to dashboard...</div>
        </div>
    );
};

export default Impersonate;
