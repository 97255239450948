import { useSelector } from 'react-redux';
import { useGetUserTimelineQuery } from '../../app/services/auth';
import { BsCheckLg } from 'react-icons/bs';
import { formatDate } from '../../utils/constants';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useState } from 'react';

function HomeTimeline() {
    const { user } = useSelector((state) => state.auth);
    const { data: timeline } = useGetUserTimelineQuery(user?.id, { skip: !user?.id });

    const [timeLineOpen, setTimeLineOpen] = useState(true);

    return (
        <div className="my-4 space-y-4">
            <div
                className="flex items-center gap-x-4 font-semibold hover:cursor-pointer"
                onClick={() => setTimeLineOpen(!timeLineOpen)}>
                {timeLineOpen ? (
                    <FaChevronUp className="h-4 w-4 pointer-events-none" />
                ) : (
                    <FaChevronDown className="h-4 w-4 pointer-events-none" />
                )}
                Timeline
            </div>
            {timeLineOpen && timeline
                ? timeline.map((ev, idx) => (
                      <div
                          key={`user-history-event-${idx}`}
                          className="grid grid-cols-[40px_1fr] items-center transition">
                          {!ev.complete ? (
                              <div className="h-6 w-6 items-center justify-center rounded-full border border-black bg-offwhite text-white"></div>
                          ) : (
                              <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-400 text-white">
                                  <BsCheckLg className="h-3 w-3" />
                              </div>
                          )}
                          <div className="space-y-1">
                              <h3 className="items-center sm:text-lg font-bold leading-none">
                                  {ev.label}
                              </h3>
                              {ev.date && (
                                  <time className="block font-heading text-sm leading-none tracking-widest">
                                      {formatDate(ev.date)}
                                  </time>
                              )}
                          </div>
                      </div>
                  ))
                : null}
        </div>
    );
}

export default HomeTimeline;
