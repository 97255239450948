import { api } from './auth';
// look at https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates since the client drives cart updates in general
// we shouldn
const cartApi = api.injectEndpoints({
    endpoints: (builder) => ({
        addToCart: builder.mutation({
            query: (items) => ({
                url: '/purchases/cart/add',
                method: 'POST',
                body: { orderItems: items },
            }),
            invalidatesTags: ['Cart'],
        }),
        applyDiscountToCart: builder.mutation({
            query: (discountCode) => ({
                url: `/purchases/cart/discount`,
                method: 'PUT',
                body: { discountCode: discountCode },
            }),
            invalidatesTags: (result, error) => (error ? [] : ['Cart']),
        }),
        updateInsuranceInCart: builder.mutation({
            query: (usingInsurance) => ({
                url: '/purchases/cart',
                method: 'PUT',
                body: { orderType: usingInsurance ? 'INSURANCE' : 'CASH' },
            }),
            invalidatesTags: ['Cart'],
        }),
        clearCart: builder.mutation({
            query: () => ({
                url: '/purchases/cart',
                method: 'DELETE',
            }),
            invalidatesTags: ['Cart'],
        }),
        getCart: builder.query({
            query: () => '/purchases/cart',
            providesTags: ['Cart'],
        }),
        removeFromCart: builder.mutation({
            query: (items) => ({
                url: `/purchases/cart/remove`,
                method: 'POST',
                body: { orderItems: items },
            }),
            invalidatesTags: ['Cart'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useAddToCartMutation,
    useApplyDiscountToCartMutation,
    useClearCartMutation,
    useGetCartQuery,
    useLazyGetCartQuery,
    useUpdateInsuranceInCartMutation,
    useRemoveFromCartMutation,
} = cartApi;
