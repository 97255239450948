import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { usePostHog } from 'posthog-js/react';

import { weightSchema } from '../../../utils/schemas';
import { setAnswer, nextQuestion } from '../../../features/assessment/assessmentSlice';
import { toggleModal } from '../../../features/ui/uiSlice';
import { getAgeFromDateString } from '../../../utils/constants';

//Components
import AnswerGroup from './AnswerGroup';
import {
    BigDateInput,
    TextAnswerList,
    TextCheckList,
    TextInput,
    HeightInput,
} from '../../InputComponents';
import { Modal } from '../..';
import NeedsRetest from './NeedsRetest';
import getPriorStudyModalContent from '../getPriorStudyModalContent';

const Question = ({ questionData }) => {
    const assessmentState = useSelector((state) => state.assessment);
    const dispatch = useDispatch();
    const posthog = usePostHog();

    //Determine if on coaching only path
    const { howBroughtToGem } = useSelector((state) => state.assessment.answers);
    const isCoachingPath = howBroughtToGem === 'STRUGGLE_WITH_CPAP';

    const [questionModalOpen, setQuestionModalOpen] = useState(false);

    const inputSubmitHandler = (val) => {
        const currentAnswerKey =
            assessmentState.questions[assessmentState.currentQuestionIndex].answerKey;

        if (currentAnswerKey === 'howBroughtToGem' && val === 'OTHER') {
            dispatch(toggleModal('noReasonListed'));
            return;
        }

        dispatch(setAnswer({ answer: val, answerKey: currentAnswerKey }));
        dispatch(nextQuestion(questionData.answerKey));
    };

    const getSubhead = () => {
        if (assessmentState.previouslyDiagnosed && questionData.subheadPreviouslyDiagnosed) {
            return assessmentState.answers?.howBroughtToGem === 'NEED_NEW_SLEEP_TEST'
                ? questionData.subhead || ''
                : questionData.subheadPreviouslyDiagnosed;
        } else {
            return questionData.subhead;
        }
    };

    const getTitle = () => {
        if (questionData?.titlePreviouslyDiagnosed || questionData?.title) {
            if (assessmentState.previouslyDiagnosed && questionData.titlePreviouslyDiagnosed) {
                return questionData.titlePreviouslyDiagnosed;
            }

            return questionData.title;
        }
    };

    const heightWeightDateHandler = (answer) => {
        const currentAnswerKey =
            assessmentState.questions[assessmentState.currentQuestionIndex].answerKey;

        if (currentAnswerKey === 'dateOfBirth') {
            const age = getAgeFromDateString(answer);

            if (age < 18) {
                dispatch(toggleModal('assessmentTooYoung'));
                return;
            }
        }

        dispatch(
            setAnswer({
                answer:
                    currentAnswerKey === 'heightInches' || currentAnswerKey === 'weightLbs'
                        ? +answer
                        : answer,
                answerKey: currentAnswerKey,
            }),
        );
        dispatch(nextQuestion(questionData.answerKey));
    };

    //Filter out coaching selection
    const noCoachingChoice = questionData.choices?.filter(
        (choice) => choice.value !== 'STRUGGLE_WITH_CPAP',
    );

    const questionChoices =
        //If previously diagnosed, give them these options when they exist
        questionData.choicesPreviouslyDiagnosed && assessmentState.previouslyDiagnosed
            ? //If they are a coaching program candidate, filter out insomnia from the first high risk question
              isCoachingPath && questionData?.answerKey === 'highRiskSelections'
                ? questionData.choicesPreviouslyDiagnosed.filter(
                      (choice) => choice.value !== 'INSOMNIA',
                  )
                : questionData.choicesPreviouslyDiagnosed
            : //If they are not a coaching program partner, filter out the choice
              assessmentState.partnerType !== 'SLEEP_SUPPORT' &&
                questionData?.answerKey === 'howBroughtToGem'
              ? noCoachingChoice
              : //Otherwise give them the default choices
                questionData.choices;

    // conditions for rendering stuff other than questions
    if (questionData?.answerKey === 'situationChanged') {
        return <NeedsRetest sleepStudyInvalidated={true} />;
    }

    if (questionData?.answerKey === 'needNewSleepStudy') {
        return <NeedsRetest sleepStudyInvalidated={false} />;
    }

    const isPriorStudyQuestion = questionData.answerKey === 'hasPriorStudy';

    const { linkText, modalContent, titleText } = getPriorStudyModalContent({
        isCoachingPath: isCoachingPath,
    });

    return (
        <>
            <motion.div
                initial={{ opacity: 0, translateX: '20%' }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: '-20%' }}
                transition={{ ease: 'linear', duration: '0.3' }}
                className="mx-auto max-w-4xl pb-8">
                <header
                    className={`text-center ${
                        questionData.content || questionData.type === 'checklist'
                            ? 'mb-5 xs:mb-10'
                            : 'mb-20'
                    }`}>
                    {(questionData?.subhead || questionData?.subheadPreviouslyDiagnosed) && (
                        <p className="mb-4 font-heading text-2xl xs:text-4xl lg:text-6xl">
                            {getSubhead()}
                        </p>
                    )}
                    <div
                        className={`${questionData.image && 'grid grid-cols-[1fr_90px] lg:grid-cols[1fr_200px] items-center'}`}>
                        <h2
                            className={`font-heading mb-2 text-2xl ${['highRiskSelections', 'highRiskSelections2'].includes(questionData.answerKey) || questionData.image ? 'xs:text-3xl' : 'xs:text-5xl'} lg:text-6xl`}>
                            {isPriorStudyQuestion ? titleText : getTitle()}
                        </h2>

                        {questionData.image && (
                            <img
                                src={questionData.image}
                                alt=""
                                className="max-w-[70px] max-h-[150px] lg:max-w-[190px] lg:max-h-[200px] ml-[15px] object-cover object-center"
                            />
                        )}
                    </div>

                    {questionData.underhead && (
                        <p className="lg:text-[21px] my-4">{questionData.underhead}</p>
                    )}

                    {questionData.modalLabel && (
                        <button
                            onClick={() => {
                                setQuestionModalOpen(true);
                            }}
                            className="border-b-2 border-green-400 font-semibold text-white/60 transition hover:text-white text-xs sm:text-base">
                            {isPriorStudyQuestion ? linkText : questionData.modalLabel}
                        </button>
                    )}
                </header>

                {questionData.type === 'input' && (
                    <TextInput
                        defaultValue={assessmentState.answers[questionData.answerKey] || ''}
                        placeholder={questionData.placeholder}
                        submitHandler={inputSubmitHandler}
                    />
                )}

                {questionData.content && (
                    <div className="mb-28 text-center">{questionData.content}</div>
                )}

                {questionData.type === 'date' && (
                    <BigDateInput
                        defaultValue={assessmentState.answers[questionData.answerKey] || ''}
                        submitHandler={heightWeightDateHandler}
                    />
                )}

                {questionData.type === 'height' && (
                    <HeightInput submitHandler={heightWeightDateHandler} />
                )}

                {questionData.type === 'weight' && (
                    <TextInput
                        defaultValue={assessmentState.answers[questionData.answerKey] || ''}
                        placeholder="LBS"
                        validationSchema={weightSchema}
                        maxLength={3}
                        inputMode="numeric"
                        submitHandler={heightWeightDateHandler}
                    />
                )}

                {questionData.type === 'list' && (
                    <TextAnswerList
                        choices={questionChoices}
                        choiceHandler={inputSubmitHandler}
                        multiple={questionData.multiple}
                        uniqueChoice={questionData.uniqueChoice}
                    />
                )}

                {questionData.type === 'checklist' && (
                    <TextCheckList
                        choices={questionChoices}
                        choiceHandler={inputSubmitHandler}
                        multiple={questionData.multiple}
                        uniqueChoice={questionData.uniqueChoice}
                    />
                )}

                {questionData.type === 'yesno' && <AnswerGroup questionData={questionData} />}

                {questionData.skipText && (
                    <button
                        onClick={() => {
                            dispatch(nextQuestion(questionData.answerKey));
                        }}
                        className={`mx-auto mt-10 block w-auto text-center ${
                            questionData.skipAsButton
                                ? 'btn-primary'
                                : 'border-b-2 border-green-400 font-semibold text-white/60 transition hover:text-white text-xs sm:text-base'
                        }`}>
                        {questionData.skipText}
                    </button>
                )}
            </motion.div>

            {questionData.modalLabel && (
                <Modal
                    showClose={true}
                    isOpen={questionModalOpen}
                    closeHandler={() => {
                        setQuestionModalOpen(false);
                    }}>
                    {isPriorStudyQuestion ? modalContent : questionData.modalContent}
                </Modal>
            )}
        </>
    );
};

export default Question;
