import { useEffect } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { Formik, Form, useField } from 'formik';

import {
    allowLowScores,
    finishAssessment,
    nextQuestion,
    previousQuestion,
    selectCurrentQuestionData,
    setAnswer,
} from '../../features/assessment/assessmentSlice';

//Components
import {
    AssessmentResult,
    CalculatingAssessment,
    Question,
    SaveApneaAssessment,
} from '../../components/Assessment';
import { Modal } from '../../components';

//Images
import { IoCloseSharp } from 'react-icons/io5';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as LeftArrow } from '../../images/left-arrow.svg';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import ReactGA from 'react-ga4';

export function TextareaField(props) {
    const [field, meta] = useField(props);

    return (
        <div>
            <textarea {...field} {...props}></textarea>
            {meta.touched && meta.error && (
                <p className="mt-4 text-xs font-bold text-red">{JSON.stringify(meta.error)}</p>
            )}
        </div>
    );
}

function Assessment() {
    const navigate = useNavigate();
    const { assessmentStep, currentQuestionIndex, questions } = useSelector(
        (state) => state.assessment,
    );
    const currentQuestionData = useSelector(selectCurrentQuestionData);
    const dispatch = useDispatch();

    const finalModalOpen =
        questions[currentQuestionIndex].answerKey === 'anythingElse' &&
        assessmentStep === 'questions';

    //Once they reach the finished assessment question, calculate their assessment
    useEffect(() => {
        if (questions[currentQuestionIndex].answerKey === 'finishedAssessment') {
            dispatch(finishAssessment());
        }
        window.scroll({ top: 0, behavior: 'smooth' });

        if (currentQuestionIndex === 0) {
            ReactGA.event({
                category: 'User',
                action: 'Started assessment',
            });
        } else {
            if (questions[currentQuestionIndex]?.answerKey) {
                ReactGA.event({
                    category: 'User',
                    action: 'Viewed a question',
                    label: questions[currentQuestionIndex].answerKey,
                });
            }
        }
    }, [currentQuestionIndex, dispatch, questions]);

    useEffect(() => {
        if (assessmentStep === 'result')
            ReactGA.event({
                category: 'User',
                action: 'Completed assessment',
            });
    }, [assessmentStep]);

    //Allow low scores based on posthog variant
    const lowScoresFlag = useFeatureFlagEnabled('low-scores');

    useEffect(() => {
        if (lowScoresFlag) {
            dispatch(allowLowScores(true));
        }
    }, [dispatch, lowScoresFlag]);

    return (
        <div className="container">
            <nav
                className={
                    assessmentStep === 'result'
                        ? 'mb-4 xs:mb-8 flex justify-center !pb-0 pt-4 xs:pt-8 lg:pt-16'
                        : 'flex items-end justify-between py-4 pt-8 lg:py-9 lg:pt-16'
                }>
                {assessmentStep === 'questions' && (
                    <button
                        data-attr="previous-question"
                        onClick={() => {
                            let testIndex = currentQuestionIndex;

                            testIndex--;

                            while (questions[testIndex]?.skip) testIndex--;

                            testIndex === -1
                                ? navigate(-1)
                                : dispatch(previousQuestion(currentQuestionData.answerKey));
                        }}
                        disabled={currentQuestionIndex === 0}
                        className={`block transition duration-300 ${
                            currentQuestionIndex === 0 ? 'opacity-0' : 'opacity-100'
                        }`}>
                        <LeftArrow data-attr="previous-question pointer-events-none" />
                    </button>
                )}
                <Link to="/" className="mx-auto inline-block">
                    <Logo className="w-11 lg:w-16" />
                </Link>
                {assessmentStep === 'questions' && (
                    <NavLink
                        data-attr="assessment-exit"
                        to="/"
                        className="group flex items-center font-heading leading-none tracking-widest text-offwhite">
                        <span
                            className="translate-y-[1px] opacity-50 transition group-hover:-translate-x-2 group-hover:opacity-100"
                            data-attr="assessment-exit">
                            exit
                        </span>
                        <IoCloseSharp className="text-3xl" data-attr="assessment-exit" />
                    </NavLink>
                )}
            </nav>

            <motion.div
                className={`mb-8 sm:mb-16 h-0.5 w-full bg-purple-medium ${
                    assessmentStep === 'questions' ? 'block' : 'hidden'
                }`}>
                <div
                    className="h-0.5 bg-green-400 transition-all"
                    style={{
                        width:
                            Math.ceil((100 * (currentQuestionIndex + 1)) / questions.length) + '%',
                    }}></div>
            </motion.div>

            <AnimatePresence mode="wait">
                {assessmentStep === 'questions' && (
                    <Question
                        key={`question-${currentQuestionIndex}`}
                        questionData={currentQuestionData}
                    />
                )}
                {assessmentStep === 'calculating' && <CalculatingAssessment />}
                {assessmentStep === 'result' && <AssessmentResult />}
                {assessmentStep === 'create-account' && <SaveApneaAssessment />}
            </AnimatePresence>

            <Modal
                showClose={true}
                isOpen={finalModalOpen}
                closeHandler={() => {
                    dispatch(setAnswer({ answer: '', answerKey: 'anythingElse' }));
                    dispatch(nextQuestion('anythingElse'));
                }}>
                <h2 className="mb-2 font-heading text-3xl text-gray-darker lg:mb-4 lg:text-5xl">
                    Is there anything else you would like us to know?
                </h2>
                <Formik
                    initialValues={{ anythingElse: '' }}
                    onSubmit={(data) => {
                        if (data.anythingElse.length > 0) {
                            ReactGA.event({
                                category: 'User',
                                action: 'Answered a Question',
                                label: 'anythingelse_fill',
                            });
                        } else {
                            ReactGA.event({
                                category: 'User',
                                action: 'Answered a Question',
                                label: 'anythingelse_proceed',
                            });
                        }

                        dispatch(
                            setAnswer({ answer: data.anythingElse, answerKey: 'anythingElse' }),
                        );
                        dispatch(nextQuestion('anythingElse'));
                    }}>
                    <Form>
                        <TextareaField
                            name="anythingElse"
                            id="anythingElse"
                            className="mb-8 h-40 w-full border border-gray-darker p-5 lg:mb-12 lg:px-6"
                            placeholder="Optional."
                        />
                        <button className="btn-primary w-full" type="submit">
                            Finish
                        </button>
                    </Form>
                </Formik>
            </Modal>
        </div>
    );
}

export default Assessment;
