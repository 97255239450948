import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../app/services/auth';
import { hasRole } from '../../utils/roleUtils';
import posthog from 'posthog-js';

const initialState = {
    user: null,
    isLoggedIn: localStorage.getItem('_gh_user_logged_in')
        ? localStorage.getItem('_gh_user_logged_in')
        : false,
    token: null,
    storageToken: null,
    twoFactorRequired: false,
    isPatient: false,
    isMD: false,
    impersonatorId: null,
};

const slice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        logOut: (state) => {
            state.isLoggedIn = false;
            state.user = null;
            state.token = null;
            state.storageToken = null;
            state.twoFactorRequired = false;
            state.impersonatorId = null;
            localStorage.removeItem('_gh_user_logged_in');
            posthog?.reset();
        },
        tokenReceived: (state, { payload: { access_token, storage_token } }) => {
            state.token = access_token;
            state.storageToken = storage_token;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
                const { user, access_token, storage_token, twoFactorRequired } = payload;
                state.token = access_token;
                state.twoFactorRequired = twoFactorRequired;
                if (twoFactorRequired) {
                    return;
                } else {
                    state.user = user;
                    state.isPatient = hasRole(user.userRoles, 'PATIENT');
                    state.isMD = hasRole(user.userRoles, 'MD');
                    state.storageToken = storage_token;
                    state.isLoggedIn = true;
                    state.token = access_token;
                    localStorage.setItem('_gh_user_logged_in', 'true');
                }
            })
            .addMatcher(api.endpoints.verifyCode.matchFulfilled, (state, { payload }) => {
                const { user, access_token, storage_token } = payload;

                state.twoFactorRequired = false;

                state.user = user;
                state.isPatient = hasRole(user.userRoles, 'PATIENT');
                state.isMD = hasRole(user.userRoles, 'MD');
                state.storageToken = storage_token;
                state.isLoggedIn = true;
                state.token = access_token;
                localStorage.setItem('_gh_user_logged_in', 'true');
            })
            .addMatcher(api.endpoints.getMe.matchFulfilled, (state, { payload: user }) => {
                state.user = user;
                state.isPatient = hasRole(user.userRoles, 'PATIENT');
                state.isMD = hasRole(user.userRoles, 'MD');
            })
            .addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
                state.isLoggedIn = false;
                state.user = null;
                state.token = null;
                state.storageToken = null;
                state.impersonatorId = null;
                if (window.zE) window.zE('messenger', 'logoutUser');
                localStorage.removeItem('_gh_user_logged_in');
            })
            .addMatcher(
                api.endpoints.authenticateWithImpersonationToken.matchFulfilled,
                (state, { payload }) => {
                    const { access_token, impersonatorId, storage_token, user } = payload;

                    state.user = user;
                    state.isPatient = hasRole(user.userRoles, 'PATIENT');
                    state.isMD = hasRole(user.userRoles, 'MD');
                    state.storageToken = storage_token;
                    state.isLoggedIn = true;
                    state.token = access_token;
                    state.impersonatorId = impersonatorId;
                    localStorage.setItem('_gh_user_logged_in', 'true');

                    // Log additional information for troubleshooting
                    console.log('Impersonation session active for user:', user.email);
                },
            );
    },
});

export const { logOut, tokenReceived } = slice.actions;

export default slice.reducer;
